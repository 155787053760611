import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Confirmation2 from "./component/summary_page/Confirmation2";
import Confirmation from "./component/summary_page/Confirmation";
import MobileSummary from "./component/Mobile/MobileSummary";
import Confirmation3 from "./component/summary_page/Receipt";
import "./App.css";
import PartialAndCompletRecipt from "./component/summary_page/PartialAndCompletRecipt";
import MainPage from "./component/Mobile/MainPage";
import MainV4 from "./component/Mobile/webApp/MainV4";
import VocherRecipt from "./component/summary_page/VocherRecipt";
import LeadGeneration from "./component/Mobile/leadGeneration/LeadGeneration";
import DeleteAccount from "./component/GooglePolicy/DeleteAccount";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/plan" element={<MainPage />} />
        <Route
          path="/voucher/:generatedHex"
          element={<VocherRecipt />}
        />
        <Route path="/4" element={<MainV4/>}/>
        <Route path="/:phone" element={<MainPage />} />
        <Route path="/summary" element={<MobileSummary />} />
        <Route
          path="/confirm/:generatedHex"
          element={<PartialAndCompletRecipt />}
        />
        <Route path="/receipt/:generatedHex" element={<Confirmation3 />} />

        <Route path="/payment/:generatedHex" element={<Confirmation2 />} />
        <Route
          path="/:security/:installation/:planId/:planFrequency/:totalAplliances"
          element={<Confirmation />}
        />
        <Route path="/contact-us" element={<LeadGeneration />} />
        <Route path="/contact" element={<LeadGeneration />} />
        <Route path="/account/delete" element={<DeleteAccount />} />
      </Routes>
    </BrowserRouter>
  );
}
